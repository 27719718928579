import { FieldOption } from "components/common/ui/form-elements/formElements";

export enum PATHWAY {
  urgent_care = "urgent_care",
  alternative_clinical = "alternative_clinical",
  non_clinical = "non_clinical",
  special_circumstance = "special_circumstance",
  training = "training",
  itap = "ITAP",
}

export enum Category {
  resus = "resus",
  hours = "hours",
  audit = "audit",
  quiz = "quiz",
  sac = "sac",
  pdp = "pdp",
  peer = "peer",
  edu = "edu",
  additional = "additional",

  tp_supervision = "[tp]_supervision",
  tp_ucpex = "[tp]_ucpex",
  tp_practical = "[tp]_practical",
  tp_uc_course = "[tp]_uc_course",
  tp_core_skills = "[tp]_core_skills",
  tp_case_studies = "[tp]_case_studies",
  tp_uni_papers = "[tp]_university_papers",
  tp_med_lit = "[tp]_medical_literature_project",
}

interface CategoryOption extends FieldOption<Category> {
  unitType?: "hourly" | "event";
  restrictedPathway?: PATHWAY[];
}

const nonTrainingPathways = [
  PATHWAY.urgent_care,
  PATHWAY.alternative_clinical,
  PATHWAY.non_clinical,
  PATHWAY.special_circumstance,
] as PATHWAY[];

const trainingProgrammeOptions: CategoryOption[] = [
  {
    value: Category.tp_supervision,
    label: "Supervision",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_uc_course,
    label: "Urgent Care course",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_case_studies,
    label: "Case studies",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_ucpex,
    label: "UCPEX",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_practical,
    label: "Practical courses",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_core_skills,
    label: "Core skills",
    restrictedPathway: [PATHWAY.training],
  },

  {
    value: Category.tp_uni_papers,
    label: "University papers",
    restrictedPathway: [PATHWAY.training],
  },
  {
    value: Category.tp_med_lit,
    label: "Medical Literature project",
    restrictedPathway: [PATHWAY.training],
  },
];

export const activityCategoryOptions: CategoryOption[] = [
  { value: Category.hours, label: "Clinical Hours" },
  {
    value: Category.sac,
    label: "Structured Annual Conversation",
    altLabel: "SAC",
    restrictedPathway: nonTrainingPathways,
  },
  {
    value: Category.peer,
    label: "Peer Group Activity",
    unitType: "hourly",
    altLabel: "Peer Group",
  },
  {
    value: Category.resus,
    label: "Resuscitation Course",
    altLabel: "Resusitation",
  },
  { value: Category.audit, label: "Audit" },
  { value: Category.quiz, label: "Essential Knowledge Quiz" },
  {
    value: Category.edu,
    label: "Continuing Medical Education",
    unitType: "hourly",
    altLabel: "CME",
    restrictedPathway: nonTrainingPathways,
  },
  { value: Category.additional, label: "Additional CPD", unitType: "hourly" },
  ...trainingProgrammeOptions,
];

export const subTypeConditionalFields = [
  Category.edu,
  Category.audit,
  Category.resus,
  Category.additional,

  Category.tp_supervision,
  Category.tp_ucpex,
  Category.tp_practical,
  Category.tp_core_skills,
  Category.tp_case_studies,
  Category.tp_uc_course,
  Category.tp_uni_papers,
  Category.tp_med_lit,
];

export const educationSubcategory = [
  { value: "edu_con", label: "Educational Conference" },
  { value: "edu_course", label: "Educational Course" },
  { value: "edu_work", label: "Educational Workshop" },
  { value: "lna", label: "Learning Needs Assessment" },
  { value: "reading", label: "Journal or Text Reading" },
  { value: "candidate_exam", label: "Examining Candidates for RNZCUC" },
  { value: "mentor", label: "Supervising or Mentoring" },
  { value: "teach", label: "Teaching" },
  { value: "publication", label: "Medical Journal/Text Publication" },
  { value: "research", label: "Urgent Care Research" },
  { value: "expertise", label: "Giving Expert Clinical Advice" },
  { value: "presentation", label: "Scientific Presentation" },
  { value: "assessor", label: "Urgent Care Assessor Work" },
  { value: "audit_dev", label: "Clinical Audit Development" },
  { value: "exam", label: "Relevant Formal Examination" },
  { value: "podcast", label: "Podcast" },
  { value: "quiz", label: "Relevant Online Quiz" },
  { value: "other", label: "Other (please specify)" },
];

export const tpCoreSkillsSubcategory = [
  { value: "core_skills_abscess", label: "Abscess - incising and draining" },
  { value: "core_skills_burns", label: "Acute Management of Burns" },
  { value: "core_skills_wounds", label: "Acute Wound Management" },
  {
    value: "core_skills_nailed",
    label: "Assessment and management of nailed injuries",
  },
  { value: "core_skills_application", label: "Cast application" },
  { value: "core_skills_check", label: "Cast check" },
  { value: "core_skills_nerve", label: "Cranial nerve examination" },
  { value: "core_skills_body", label: "Cutaneous foreign body removal" },
  { value: "core_skills_block", label: "Digital nerve block" },
  { value: "core_skills_ear", label: "Ear examination" },
  { value: "core_skills_ecg", label: "ECG" },
  { value: "core_skills_elbow", label: "Elbow and forearm examination" },
  { value: "core_skills_epistaxis", label: "Epistaxis" },
  { value: "core_skills_face", label: "Face examination" },
  { value: "core_skills_foot", label: "Foot and ankle examination" },
  {
    value: "core_skills_cornea",
    label: "Foreign body removal from cornea or conjunctiva",
  },
  {
    value: "core_skills_mouth",
    label: "Foreign body removal from ear nose or mouth",
  },
  {
    value: "core_skills_finger",
    label: "Hand and finger fracture dislocations",
  },
  { value: "core_skills_wrist", label: "Hand and wrist examination" },
  { value: "core_skills_hip", label: "Hip examination" },
  { value: "core_skills_inhaler", label: "Inhaler, spacer and nebuliser use" },
  { value: "core_skills_arthrocentesis", label: "Knee arthrocentesis" },
  { value: "core_skills_knee", label: "Knee examination" },
  { value: "core_skills_catheterisation", label: "Male catheterisation" },
  { value: "core_skills_pelvic", label: "Pelvic examination" },
  {
    value: "core_skills_phalangeal",
    label: "Phalangeal dislocation treatment",
  },
  { value: "core_skills_acuity", label: "Recording a visual acuity" },
  { value: "core_skills_ring", label: "Ring removal" },
  { value: "core_skills_dislocation", label: "Shoulder dislocation reduction" },
  { value: "core_skills_shoulder", label: "Shoulder examination" },
  { value: "core_skills_eye", label: "Slit lamp examination of the eye" },
  {
    value: "core_skills_splint-hand",
    label: "Splint application – finger and hand",
  },
  { value: "core_skills_splint-knee", label: "Splint application – knee" },
  {
    value: "core_skills_splint-moonboot",
    label: "Splint application – moonboot",
  },
  {
    value: "core_skills_splint-wrist",
    label: "Splint application – wrist or wrist-thumb",
  },
  { value: "core_skills_suturing", label: "Suturing" },
  {
    value: "core_skills_thoracic",
    label: "Thoracic and lumbar spine examination",
  },
  { value: "core_skills_x-ray", label: "X-ray interpretation" },
];

export const tpUcCourseSubcategory = [
  { value: "medicine_course", label: "Medicine" },
  { value: "orthopaedics_course", label: "Orthopaedics" },
  { value: "plastic_course", label: "Plastic Surgery" },
  { value: "urology_course", label: "Surgery/Urology" },
  { value: "paediatrics_course", label: "Paediatrics" },
  { value: "ophthalmology_course", label: "Ophthalmology" },
  { value: "ent_course", label: "ENT" },
  { value: "sexual_course", label: "O&G/Sexual health" },
  { value: "toxicology_course", label: "Toxicology" },
  { value: "psychiatry_course", label: "Psychiatry" },
  { value: "environmental_course", label: "Environmental" },
  { value: "trauma_course", label: "Trauma" },
  { value: "miscellaneous_course", label: "Miscellaneous" },
  { value: "radiology_course", label: "Radiology" },
  { value: "academic_course", label: "Academic integrity " },
  { value: "hauora_course", label: "Hauora Māori" },
];

export const tpCaseStudiesSubcategory = [
  { value: "medical_case_study", label: "Acute Medical" },
  { value: "surgical_case_study", label: "Acute Surgical" },
  { value: "orthopaedics_case_study", label: "Acute Orthopaedics" },
  { value: "plastics_case_study", label: "Acute Plastics" },
  { value: "paediatrics_case_study", label: "Acute Paediatrics" },
  { value: "comms_case_study", label: "Communication" },
];

export const subCategoryOptions: {
  [K in Category]?: FieldOption[];
} = {
  [Category.edu]: educationSubcategory,
  [Category.resus]: [
    { value: "refresh", label: "4-hour Refresher" },
    { value: "full", label: "Full, 8-hour resuscitation course" },
  ],
  [Category.audit]: [
    { value: "patient", label: "MSF Survey Audit" },
    { value: "clinical", label: "Clinical Notes Audit" },
    { value: "tikanga", label: "Tikanga Māori Audit" },
    { value: "ref_data", label: "Referral Data Assessment Audit" },
    { value: "ref_patient", label: "Referral Patient Outcome Audit" },
    { value: "fracture", label: "Fracture X-Ray Interpretation Audit" },
    { value: "suture", label: "Suture Outcome Audit" },
    { value: "ces", label: "CES Red Flag Audit" },
    { value: "vital_signs", label: "Vital Signs in Back Pain Audit" },
    {
      value: "other",
      label: "Other (please specify)",
      altLabel: "Other Approved Audit",
    },
  ],
  [Category.additional]: [
    { value: "supervisor_training", label: "Supervisor training" },
    ...educationSubcategory,
  ],

  // Training Programme
  [Category.tp_supervision]: [
    { value: "supervision_agreement", label: "Supervision Agreement" },
    { value: "supervision_meeting", label: "Supervision Meeting" },
  ],
  [Category.tp_ucpex]: [
    { value: "upex_written", label: "UCPEX: Written" },
    { value: "upex_osce", label: "UCPEX: OSCE" },
    { value: "upex_overall", label: "UCPEX: Overall" },
  ],
  [Category.tp_practical]: [
    { value: "pals_practical", label: "PALS/APLS" },
    { value: "trauma_practical", label: "Trauma" },
    { value: "comms_practical", label: "Communications" },
    {
      value: "skills_demo",
      label: "Skills demonstration and assessment weekend",
    },
  ],
  [Category.tp_core_skills]: tpCoreSkillsSubcategory,
  [Category.tp_case_studies]: tpCaseStudiesSubcategory,
  [Category.tp_uc_course]: tpUcCourseSubcategory,
  [Category.tp_uni_papers]: [
    { value: "paediatrics_paper", label: "Paediatrics" },
    { value: "epidemiology_paper", label: "Clinical epidemiology" },
    { value: "other", label: "Registrar chosen (approved by RNZCUC)" },
  ],
  [Category.tp_med_lit]: [
    { value: "mlp_med_lit", label: "MLP" },
    { value: "pop_hlth_701_med_lit", label: "PoplHlth701" },
    { value: "other", label: "Other" },
  ],
};

export enum Waiver {
  not_required = "not_required",
  not_completed = "not_completed",
  rpl = "rpl",
  already_completed = "already_completed",
}

export enum Grade {
  pass = "pass",
  fail = "fail",
  conceded = "conceded",
}

interface WaiverOption extends FieldOption<Waiver> {}

export const activityWaiverOptions: WaiverOption[] = [
  { value: Waiver.not_required, label: "Not Required" },
  { value: Waiver.not_completed, label: "Not Completed" },
  { value: Waiver.rpl, label: "RPL" },
  { value: Waiver.already_completed, label: "Already Completed" },
];

interface GradeOption extends FieldOption<Grade> {}

export const gradeOptions: GradeOption[] = [
  { value: Grade.pass, label: "Passed", theme: "success" },
  { value: Grade.fail, label: "Failed", theme: "danger" },
  { value: Grade.conceded, label: "Conceded Pass", theme: "warning" },
];

export enum GradeResult {
  pass_distinction = "pass_distinction",
  pass_merit = "pass_merit",
  pass = "pass",
  fail = "fail",
  conceded = "conceded",
}

interface GradeResultOption extends FieldOption<GradeResult> {}

export const gradeResultOptions: GradeResultOption[] = [
  {
    value: GradeResult.pass_distinction,
    label: "Passed with Distinction",
    theme: "success",
  },
  {
    value: GradeResult.pass_merit,
    label: "Passed with Merit",
    theme: "success",
  },
  { value: GradeResult.pass, label: "Passed", theme: "success" },
  { value: GradeResult.fail, label: "Failed", theme: "danger" },
  { value: GradeResult.conceded, label: "Conceded Pass", theme: "warning" },
];

const gradeLetterScale = [
  "A+",
  "A",
  "A-",
  "B+",
  "B",
  "B-",
  "C+",
  "C",
  "C-",
  "D+",
  "D",
  "D-",
  "F",
] as const;

export type GradeLetterScale = typeof gradeLetterScale[number];

export const gradeLetterScaleOptions: FieldOption<GradeLetterScale>[] =
  gradeLetterScale.map((v) => ({ value: v }));

export enum ActivityReqReason {
  overdue = "overdue",
  special = "special",
}

interface ActivityReqOption extends FieldOption<ActivityReqReason> {}

export const activityRequiredReasonOptions: ActivityReqOption[] = [
  { value: ActivityReqReason.overdue, label: "Overdue" },
  { value: ActivityReqReason.special, label: "Special" },
];
